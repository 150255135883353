import React, { Component, Fragment } from 'react';
import ReactCountryFlag from "react-country-flag";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMenu, getCategorias } from '../../toolbox/wpApi';
import { typeMenuItem } from 'Entities';
import { Link } from 'react-router-dom'

type State = {
  open: boolean
  nightModeSwitch: boolean
  items: Array<navItem>
  idioma: string,
  categorias: Array<string>
  submenu: boolean
}

type Props = {
  trocarIdioma: (arg:string)=>void
  nightMode: (arg:boolean)=>void
}

type navItem = {
  idioma:string, 
  menu: typeMenuItem
}

export default class Menu extends Component<Props, State> {
  
  state = {
      categorias:[],
      open: false,
      idioma: "pt-br",
      nightModeSwitch: this.isNight(),
      submenu: false,
      items: [{
        idioma:"pt-br", 
        menu: {
            AreadoCliente: "",
            Clientes: "",
            Contato: "",
            Inovacao: "",
            Inicio: "",
            Portifolio: "",
            Sobre: ""
        }
      }]
  }
  getStorage = async()=>{
    if(localStorage.getItem('idioma')){
      return String(localStorage.getItem('idioma'));
    }
    localStorage.setItem('idioma', "pt-br");
    return String(localStorage.getItem('idioma'));
  }

	async componentWillMount(){
		this.setState({ 
      items: await getMenu(),
      idioma: await this.getStorage(),
      categorias: await getCategorias(),
    })
  }

  gerarCategorias(array){
    function nExiste(v, a){
      if(!v) return false
      return a.indexOf(v)!==-1?false:true
    }
    let categoriasUnicas:Array<{idioma:string,categorias:Array<string> }> = [{
      idioma: "pt-br",
      categorias: []
    },{
      idioma: "en-us",
      categorias: []
    },{
      idioma: "zh-cn",
      categorias: []
    }];
    array.forEach(element => {
      nExiste(element.ptbr, categoriasUnicas[0].categorias) && categoriasUnicas[0].categorias.push(element.ptbr)
      nExiste(element.enus, categoriasUnicas[1].categorias) && categoriasUnicas[1].categorias.push(element.enus)
      nExiste(element.zhcn, categoriasUnicas[2].categorias) && categoriasUnicas[2].categorias.push(element.zhcn)
    });
    return categoriasUnicas;
  }

  isNight(){
    if(sessionStorage.getItem("nightMode")){
        return sessionStorage.getItem("nightMode") === "true";
    }
    let data = new Date();
    if (data.getHours() <= 7 || data.getHours() > 19) 
        return true;
    return false;
  };
  render() {
      let submenu = this.state.submenu && "submenuOpen";
      let submenuTitulo = this.state.submenu && "submenuTitulo";
      return (
        <div className="menu">
          
          <ul className={ this.state.open ? "open" : ""  }>
                { this.state.open ? 
                  (<a className="nav-close" onClick={ ()=>this.setState( {open:false, submenu:false} )}><FontAwesomeIcon icon="times" /></a>)
                 : 
                  (<a className="nav-open" onClick={ ()=>this.setState( {open:true} )}><FontAwesomeIcon icon="bars" /></a>)
                }

                { (this.state.open) && (
                  <div className="flags animated fadeInUp">                    
                    <Link to="/" onClick={  ()=> {
                      this.props.trocarIdioma("pt-br"); 
                      localStorage.setItem('idioma', "pt-br"); 
                      this.setState( { open:false,submenu:false, idioma:"pt-br" })
                    }}><ReactCountryFlag styleProps={{width: '3rem' }} svg code="br" /></Link>
                   <Link to="/" onClick={ ()=> {
                      this.props.trocarIdioma("en-us"); 
                      localStorage.setItem('idioma', "en-us"); 
                      this.setState( { open:false,submenu:false, idioma:"en-us" })
                    }}><ReactCountryFlag styleProps={{width: '3rem' }} svg code="us" /></Link>
                    <Link to="/"  onClick={ ()=> {
                      this.props.trocarIdioma("zh-cn"); 
                      localStorage.setItem('idioma', "zh-cn"); 
                      this.setState( { open:false,submenu:false, idioma:"zh-cn" })} 
                    }><ReactCountryFlag styleProps={{width: '3rem' }} svg code="cn" /></Link>
                  </div>
                )}
                  
                { this.state.items.filter((i:navItem)=> i.idioma === this.state.idioma).map(
                   (item:navItem,keyX:number) => {
                     if(this.state.open){
                      return(
                      <Fragment key={keyX}> 
                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": "0s" }}>
                          <Link to="/" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.Inicio}</Link>
                        </li>
                        <li className={`menuItem animated fadeInUp ${submenuTitulo}`} style={{ "animationDelay": ".1s" }}>
                          <a onClick={()=>{
                            this.setState({submenu:!this.state.submenu})
                          }}>{ this.state.submenu ?  <FontAwesomeIcon icon="arrow-left" /> : item.menu.Portifolio }</a>
                        </li>

                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": ".2s" }}>
                          <Link to="/inovacao" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.Inovacao}</Link>
                        </li>
                        
                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": ".3s" }}>
                          <Link to="/clientes" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.Clientes}</Link>
                        </li>
                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": ".4s" }}>
                          <Link to="/sobre" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.Sobre}</Link>
                        </li>
                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": ".5s" }}>
                          <Link to="/Contato" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.Contato}</Link>
                        </li>
                        <li className={`menuItem animated fadeInUp ${submenu}`} style={{ "animationDelay": ".6s" }}>
                          <Link to="/area-do-cliente" onClick={ ()=>this.setState( {open:false,submenu:false} )}>{item.menu.AreadoCliente}</Link>
                        </li>
                      </Fragment>);
                     }
                     return null;
                   }
                )}
                { (this.state.open) && this.gerarCategorias(this.state.categorias).filter((i)=> i.idioma === this.state.idioma).map(
                   (item,key2) => {
                      return(
                      <Fragment key={key2}>
                        {(this.state.submenu) && (
                          <div className="submenu">
                          {item.categorias.map((cat,key3)=>{
                            return(
                            <li key={key3} className="submenuItem animated fadeInUp" style={{ "animationDelay": key3/10+"s" }}>
                              <Link to={{ pathname: "/", search:`?category=${cat}` }} onClick={ ()=>this.setState( {open:false,submenu:false} )} >
                              {cat}
                              </Link>
                            </li>
                            )
                          })}
                          </div>
                        )}
                      </Fragment>);
                     return null;
                   }
                )}

                 { this.state.open && (
                    <label htmlFor="sliderxy" className="nightModeSwitch animated fadeInUp ">
                      <input id="sliderxy" type="checkbox" defaultChecked={!this.state.nightModeSwitch} />
                      <span className="sliderx" onClick={ ()=> {
                          this.setState({nightModeSwitch:!this.state.nightModeSwitch},
                            ()=>this.props.nightMode(this.state.nightModeSwitch)
                          );
                      } }></span>
                    </label>
                  )}

          </ul>
          <div className="fundoPreto" onClick={ ()=>this.setState( {open:false,submenu:false} )}></div>
        </div>
      );
  }
};
