import React, {Component} from 'react';
import Layout from '../Layout';
import "./AreaDoCliente.scss"
//import ReCAPTCHA from "react-google-recaptcha";
import { login } from '../../toolbox/wpApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type State = {
    col: number,
    items: Array <{media:string, key:number} >,
    logado: boolean,
    login: string,
    senha: string,
    recap: boolean,
    carregamento: string
}
type Props = {
    className: string ,
}

export default class AreaDoCliente extends Component<Props,State> {
    state = {
        col: 3,
        items: [],
        logado: false,
        login: "",
        senha: "",
        loaded: false,
        recap: false,
        carregamento: "Login"
    }

    getClientImages= async(e)=>{
      e.preventDefault();
      this.setState({carregamento: "carregando"});
      const item = await login( this.state.login, this.state.senha )
      setTimeout(async() => {
        this.setState({ 
          items: await item
        }, ()=> {
            if(this.state.items.length >= 1){
              this.setState({ logado: true, carregamento: "pronto" })
              window.scrollBy({
                top: window.screen.height, 
                left: 0, 
                behavior: 'smooth'
              });
            }else{
              alert("senha invalida !");
              this.setState({carregamento: "Login"});
            }
        });
      }, 2000);
    }
    zipAll(){
      const zip:Array<{key: number, media: string}> = this.state.items;
      return zip[0].media.split("/").slice(0,-1).join("/")+"/projeto.zip";
    }

    isFile(item, key){
      const fileName = item.media.split("/").pop();
      const ext = fileName.split('.').pop();

      if( fileName === "projeto.zip"){
        return null;
      } else if(ext === fileName){
        console.log("ext === fileName", ext, fileName);
        return <h2 className='folderName'>{ ext }</h2>;
      }else{
       return (<a className="downloadItem"  href={item.media} download key={key}>
                <FontAwesomeIcon icon="image" />  {item.media.split("/").pop()}
              </a>)
      }
    }

    render() {
        return (
            <Layout className={this.props.className}>
                <div className="area-login">
                  <form onSubmit={ this.getClientImages }>
                    <h1>ÁREA DO CLIENTE</h1>
                    <p>Entre com seu login e senha para ter acesso a seus arquivos</p>
                    <div className="input-group">
                      <input id="login" name="name" placeholder="Login" type="text" 
                        onChange={( e=>this.setState({ login:e.target.value }) )} />
                    </div>
                    <div className="input-group">
                      <input id="senha" name="senha" placeholder="senha" type="password"
                        onChange={( e=>this.setState({ senha:e.target.value }) )} />
                    </div>
                    <div className="input-group" style={{padding: "1rem"}}>

                      {this.state.carregamento === "Login" && <input
                          className={this.state.carregamento}
                          placeholder="Login"
                          type="submit"
                          value="Login"
                          id="submit"
                      />}

                      {this.state.carregamento === "carregando" && <input
                          className={this.state.carregamento}
                          placeholder="Carregando..."
                          type="submit"
                          value="Carregando..."
                          id="submit"
                      />}

                      {this.state.carregamento === "pronto" && <input
                          className={this.state.carregamento}
                          placeholder="Pronto!"
                          type="submit"
                          value="Pronto!"
                          id="submit"
                      />}
                      
                    </div>
                        {/* <ReCAPTCHA
                          sitekey="Your client site key"
                          onChange={()=>this.setState({recap:true})}
                        /> */}
                    </form>
                </div>
                {(this.state.logado) && 
                  <div className="downloadContainer">
                    <a className="downloadAll" href={ this.zipAll() } download><FontAwesomeIcon icon="images" />  Baixar Todos</a>
                    {<div className="downloadContainerList">
                      { this.state.items.map((item : {key: number, media: string}, key: number) => 
                         this.isFile(item, key)
                        ) }
                    </div>}
                  </div>
                }
            </Layout>
        );
    }
}
