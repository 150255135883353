import axios from 'axios';
import { typeLogo, typeProjeto, typeLogoClients, typeMenu, typeMenuItem } from 'Entities';

export const urlMaster = "https://lemoncg.com.br/admin";
export const urlRoot = "https://lemoncg.com.br";

export async function login(login, senha){
    const res = await axios({
        headers: {
            "content-type": "application/json; charset=utf-8",
            "Accept": "application/json; charset=utf-8",
        },
        url: urlRoot+"/LemonLogin.php",
        method: 'POST',
        timeout: 2000,
        data:{
            login: login,
            senha: senha
        }
    });
    return res.data;
}

export async function sendMail(name, email, message){
    let texto: Array<any> = [];
    await axios.post(urlRoot+`/mailLemon.php`,{
        name: name,
        email: email,
        message: message
    }).then( res => {
        texto = res.data;
    });
    return texto;
}


export async function getIdioma(){
    let texto: Array<any> = [];
    await axios.get(urlMaster+`/wp-json/wp/v2/menu`).then(res => {
        res.data.map((item, key)=> texto.push({
            key: key,
            image: item.meta_box.logo[0].full_url
        }));
        return texto;
    });
}
    export async function getFooter(){
        let imgs:Array<{idioma:string,direitos:string, desenvolvimento:string}> = []; 
        await axios.get(urlMaster+`/wp-json/wp/v2/Footer`).then( res=> {
     
            res.data.map( (item)=> imgs.push({
                idioma: item.slug,
                desenvolvimento: item.meta_box.Footer.desenvolvimento,
                direitos: item.meta_box.Footer.direitos
            }));
        });
        return imgs
    }



export async function getMenu(){
    let imgs:Array<{ idioma:string, menu: typeMenuItem}> = [];
    await axios.get(urlMaster+`/wp-json/wp/v2/Menu?per_page=100`).then(res => {
        res.data.map( (Menu:typeMenu) => imgs.push({
            idioma: Menu.slug, 
            menu: Menu.meta_box.Menu,
        }))
    });
    return imgs
}

export async function getCategorias(){
    let categorias:Array<string> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/portifolio?per_page=100`).then(res => 
        res.data.map( i =>{
            return (i.meta_box.CategoriasLemon) && categorias.push(i.meta_box.CategoriasLemon)
        })
    )
    return categorias;
}

export async function getProjetos(){
    let imgs:Array<typeProjeto> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/portifolio?per_page=100`).then(res => {
        return res.data.map( i => {
            //console.log("projetos", i)
            if(i.meta_box.youtubeVideo.url){
                const id = i.meta_box.youtubeVideo.url.split("watch?v=")[1];
                const youtubeVideo = {
                    url: i.meta_box.youtubeVideo.url,
                    tumb: `https://img.youtube.com/vi/${id}/0.jpg` 
                }
                return imgs.push({
                    "nome": i.title.rendered,
                    "video": youtubeVideo,
                    "medias": i.meta_box.Portifolio,
                    "tile": i.slug, 
                    "link": i.slug,
                    "legenda": i.meta_box.legenda.legenda,
                    "category": i.meta_box.CategoriasLemon
                })
            }
            return imgs.push({
                "nome": i.title.rendered,
                "video": {url:"",tumb:""},
                "medias": i.meta_box.Portifolio,
                "tile": i.slug, 
                "link": i.slug,
                "legenda": i.meta_box.legenda.legenda,
                "category": i.meta_box.CategoriasLemon
            })
        });
    });
    return imgs;
}





export async function getInovacoes(){
    let imgs: Array <{thumbnail:string, link:string} > = []; 
    
    await axios.get(urlMaster+`/wp-json/wp/v2/Inovacao?per_page=100`).then(res => {
        return res.data.map( (i) => {
            console.log("projetos", i)
            return imgs.push({
                "thumbnail": i.meta_box.thumbnail,
                "link": i.meta_box.link,
            })
        });
    });
    return imgs;
}

export async function getClientsLogo(){
    let imgs:Array<{ key:number, image:string }> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/LogoClients?per_page=100`).then( res=> {
        res.data.map( (item:typeLogoClients, key:number)=> imgs.push({
            key: key,
            image: item.meta_box.LogoClients[0].full_url
        }));
    });
    return imgs
}

export async function getSobre(){
    let imgs:Array<{ key:number,idioma:string, texto:string, SobreImgs:Array<string> }> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/Sobre`).then( res=> {
        //console.log("contato", res.data)
        res.data.map( (item:any, key:number)=> imgs.push({
            key: key,
            idioma: item.slug,
            texto: item.meta_box.Sobre,
            SobreImgs: item.meta_box.SobreImgs
        }));
    });
    return imgs
}

export async function getContato(){
    let imgs:Array<{
        idioma: string,
        image:Array<{url:string}>,
        links
        textos:{ 
            nameEmpty: string,
            mailEmpty: string,
            textEmpty: string,
            sucessMessagem: string,
            hangoutLabel: string,
            nameLabel: string,
            mailLabel: string,
            enviarLabel: string
        }
      }> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/Contato`).then( res=> {
        res.data.map( (item:any)=> imgs.push({
            idioma: item.slug,
            image:  item.meta_box.img,
            textos: item.meta_box.Contato,
            links: item.meta_box["links-de-contato"]
        }));
    });
    return imgs
}

export async function getMidiasSociais(){
    let imgs={facebook:"",instagram:"",twitter:""}; 
    await axios.get(urlMaster+`/wp-json/wp/v2/MidiasSociais`).then( res=> {
        imgs = {
            facebook: res.data[0].meta_box.MidiasSociais.facebook,
            instagram: res.data[0].meta_box.MidiasSociais.instagram,
            twitter: res.data[0].meta_box.MidiasSociais.twitter
        }
    });
    
    return imgs
}


export async function getLogo(){
    let imgs:Array<{ key:number, dia:string, noite:string }> = []; 
    await axios.get(urlMaster+`/wp-json/wp/v2/Logo`).then( res=> {
        res.data.map( (item:typeLogo, key:number)=> imgs.push({
            key: key,
            dia: item.meta_box.logo[0].full_url,
            noite: item.meta_box.logo[1].full_url
        }));
    });
    return imgs
}

export async function getImageFolder(){
    let img = {"video": false, "url":""} 
    await axios.get(urlMaster+`/wp-json/wp/v2/imageopen`).then( res=> {
        const item = res.data[0].meta_box.ImageOpen[0];
        if(item.fileformat === "mp4"){
            img = {
                "video":true,
                "url":item.url
            }
        }else{
            img = {
                "video":false,
                "url":item.url
            }
        }
    });
    return img;
}


export function getConfig(callback){
    //apeans usado em testes
    axios.get(urlMaster+`/wp-json/wp/v2/ImageOpen`).then(res => {
        callback(res.data);
    });
}