import React, { Component } from 'react';
//import Carousel from 'nuka-carousel';

import Layout from './Layout';
import Tile from "./Masonry/Tile";

import { getProjetos, getMenu } from "../toolbox/wpApi";

import LazyLoad from 'react-lazyload';
import { typeProjeto, typeMenuItem } from 'Entities';

// import ImageFolder from './ImageFolder/ImageFolder';
// import Image8 from './Image8';
import LightBox from './LightBox';

type State = {
  masonryTitle: string
  logos: Array<{ key:number, image:string }>,
  scroll: {
    position: string
  }
  projetos: Array<typeProjeto>
  categorias: Array<{title:string}>
  isMobile: boolean
  menu: Array<{
    idioma:string, 
    menu: typeMenuItem
  }>
}

type Props = {
  className: string
  idioma: string,
  search: string | null
}

export default class Main extends Component<Props, State> {
  state = {
    masonryTitle: "",
    ImageFolder: {video: false, url:""},
    projetos: [],
    categorias: [],
    logos: [],
    menu:[],
    scroll: {
      position: "relative"
    },
    isMobile: !!(window.screen.width < 900),
  };
  componentWillUpdate(){
    window.dispatchEvent(new Event('resize'));
  }

  filtrePortifolio(array){
    return array.filter((item:typeProjeto) => {
      if(!this.props.search) return true;
      if(this.props.idioma.trim() == "pt-br"){
        return item.category.ptbr === this.props.search
      }else if(this.props.idioma.trim() == "en-us"){
        return item.category.enus === this.props.search
      }else if(this.props.idioma.trim() == "zn-ch"){
        return item.category.zhcn === this.props.search
      }else{
        return item.category.ptbr === this.props.search
      }
    })
  }
  
  async componentWillMount(){
		this.setState({ 
        projetos: await getProjetos(),
        menu: await getMenu() 
      }
    );
  }

  
  render() {
    return (
      <Layout className={this.props.className}>
        <section id="portifolio" >
          <div className='masonry-container'>

              {this.filtrePortifolio(this.state.projetos).map(( item:typeProjeto, key) => {
                if(item.medias){
                  return( 
                    <LazyLoad key={key} height={50}>
                      <LightBox id={key} imgSet={ this.filtrePortifolio(this.state.projetos)}>
                          <Tile namega={item.nome} nome={item.legenda } src={item.medias[0].url} placeholder={item.medias[0].sizes.medium_large.file} key={key} />
                      </LightBox>
                    </LazyLoad>
                  );
                }
                return <div style={{"display":"none"}}></div>
 
              })}
          </div>
        </section>
      </Layout>
    );
  }
}
