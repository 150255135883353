import React, { Component } from 'react';
import Layout from '../Layout';
import { getSobre } from '../../toolbox/wpApi';
import "./QuemSomos.scss"

type State = {
  texto: Array<{texto:string,idioma:string}>
}

type Props = {
  className: string
  idioma: string
  nightMode: boolean
}

export default class QuemSomos extends Component<Props, State> {
  state = {
    texto: [{texto:'',idioma:""}]
  };

  async componentWillMount(){
		this.setState({ texto: await getSobre() })
  }
  
  getTexto=(i,k)=>{
    return (<p key={k}>{ i.texto }</p>)
  }
  getImage=(i)=>{
    return i.SobreImgs.map( (imagem,k) => (<div className="membrana"><img key={k} src={imagem.full_url} alt={imagem.alt}/></div>))
  }
  
  render() {
    //console.log(this.state)
    if(!this.state.texto[0].texto.length){
        return (
          <Layout className={this.props.className}>
            <div className="container">
              <div className="QuemSomos">
                <div className="animated fadeInUp">
                  <h1>Sobre a Lemon</h1>
                </div>
                <div className="animated fadeInUp">
                  <div className="lds-hourglass"></div>
                </div>
              </div>
            </div>
          </Layout>
        );
    }
    return (
      <Layout className={this.props.className}>
        <div className="container">
          <div className="QuemSomos">
            <div className="animated fadeInUp">
              <h1>Sobre a Lemon</h1>
            </div>
            <div className="animated fadeInUp">
              {
                this.state.texto.filter((i)=> i.idioma.trim() === this.props.idioma.trim()).map( (i,k)=> this.getTexto(i,k) )
              }
            </div>
            <div className="animated fadeInUp row">
              {
                this.state.texto.filter((i)=> i.idioma.trim() === this.props.idioma.trim()).map( (i)=> this.getImage(i) )
              }
            </div>
     
          </div>
        </div>
      </Layout>
    );
  }
};
