import React, {Component} from 'react';
import Layout from '../Layout';
import "./Inovacao.scss";
import { getInovacoes } from '../../toolbox/wpApi';
import TileD from './TileD';
import Iframe from 'react-iframe';

type State = {
    open: boolean,
    link: string,
    projetos: Array <{thumbnail:string} >
}
type Props = {
    className: string
}

export default class Inovacao extends Component<Props,State> {
    state = {
        open: false,
        link: "",
        projetos: [],
    }

    async componentWillMount(){
      this.setState({ 
          projetos: await getInovacoes()
        }
      );
    }
    openVideo(){

    }
    render() {
    
        return (
            <Layout className={this.props.className}>
                <div className="Inovacao">
                  <div className="inovacaoInner">
                    {this.state.projetos.map( (item:{thumbnail:any, link:any}, key)=>{
                        console.log(item.thumbnail[0]);
                        return(
                          <TileD link={item.link.link} src={ item.thumbnail[0].url }  key={key} clicked={(l)=>this.setState({link: l})}></TileD>
                        )}
                    )}
                    { this.state.link.length ? (<div className="blackback" onClick={()=>this.setState({ link:"" })}>
                        <div className={`popup`}>
                          <Iframe url={ `https://www.youtube.com/embed/${this.state.link}` }
                            id="myId"
                            className="popupinnner"
                            position="relative"/>
                        </div>
                    </div>): ""}
                  </div>
      
                </div>
            </Layout>
        );
    }
}
