import React, { Component } from 'react';
import Layout from '../Layout';
import { getClientsLogo } from '../../toolbox/wpApi';
import "./Clientes.scss"

type State = {
  LogoClients: Array<{
    key: number;
    image: string;
  }>
}

type Props = {
  className: string
  nightMode: boolean
}

export default class Clientes extends Component<Props, State> {
  state = {
    LogoClients: []
  };

  async componentWillMount(){
		this.setState({ LogoClients: await getClientsLogo() })
  }
  
  getTexto=(i)=>{
    return (<p>{ i.texto }</p>)
  }
  
  render() {
    return (
      <Layout className={"container "+this.props.className}>
        {this.state.LogoClients.map((logo:{ key:number, image:string },key)=>{
          return(
            <div className="item-portifolio"> <img src={logo.image} key={key} /></div>
          )
        })}
      </Layout>
    );
  }
};
