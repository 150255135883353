import React, { Component } from 'react';
import "./Header.scss";
import Menu from "./Menu";
import { getLogo  } from '../../toolbox/wpApi';
import { Link } from 'react-router-dom';

type State = {
  logo: Array<{dia:string, noite:string }>
}

type Props = {
  setNightMode: (mode:boolean)=>void
  setIdioma: (mode:string)=>void
  nightMode: boolean
  className: string
}

export default class HeaderX extends Component<Props, State> {
  state = {
    logo: [{dia:"",noite:''}]
  };

  async componentWillMount(){
		this.setState({ logo: await getLogo() })
	}
  
  trocarIdioma = (i:string)=>{ 
    return this.props.setIdioma(i) 
  }
  
  setNightMode=(mode)=>{
    sessionStorage.setItem("nightMode",mode+"")
    return this.props.setNightMode(mode);
  }


  render() {
    const { nightMode } = this.props;
    if(!this.state.logo[0]){
      return(
        <header className={this.props.className}>
          <Menu trocarIdioma={ this.trocarIdioma } nightMode={ this.setNightMode }></Menu>
          <Link to="/" > <img src="" /> </Link>
        </header>
      )
    }
    return (
        <header className={this.props.className}>
          <div>
            <Menu trocarIdioma={ this.trocarIdioma } nightMode={ this.setNightMode }></Menu>
            <Link to="/" >
            {
              nightMode ?
              <img src={ this.state.logo[0].dia } /> :
              <img src={ this.state.logo[0].noite }  />
            }
            </Link>
          </div>
        </header>
    );
  }
};
