import React, { Component } from 'react';
import './Masonry.scss';

  type State = {
		placeholder: string
	}
  
  type Props = {
		nome: string
		src: string
		placeholder: string,
		namega: string
  }

export default class Tile extends  Component<Props, State> {
	state = {
		placeholder: this.props.src.split("/").splice(0,this.props.src.split("/").length-1).join("/")+"/"+this.props.placeholder
	}
	
	static getDerivedStateFromProps(nextProps, prevState){
		const placeholder = nextProps.src.split("/").splice(0,nextProps.src.split("/").length-1).join("/")+"/"+nextProps.placeholder
        if(placeholder!==prevState.placeholder){
            return {placeholder:placeholder};
         }
         else return null;
    }

	render() {
		const {nome} = this.props;
		return (
			<div className="tile">
				<p>{nome}</p>
				<img src={this.state.placeholder} />
			</div>
		);
	}
}
