import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLogo, getFooter, getMidiasSociais  } from '../../toolbox/wpApi';
import "./Footer.scss";

type State = {
  logo: Array<{dia:string, noite:string}>,
  texto: Array<{idioma:string,direitos:string}>,
  socialMidia: { facebook:string, instagram:string, twitter:string }
}

type Props = {
  className: string,
  nightMode: boolean,
  idioma: string
}

export default class Footer extends Component<Props, State> {
  state = {
    logo: [{dia:"",noite:""}],
    texto: [{idioma:"",direitos:""}],
    socialMidia: { facebook:"", instagram:"", twitter:"" }
  };

  async componentWillMount(){
		this.setState({ 
      logo: await getLogo(),
      texto: await getFooter(),
    })
    this.setState({
      socialMidia: await getMidiasSociais()
    })
  }
  
  getTexto=(i,key)=>{
    return (<p key={key}>{ i.direitos }</p>)
  }

  render() {
    const { nightMode } = this.props;
    if(!!this.state.logo[0]){
      return (
        <footer className={this.props.className}>
          {
            nightMode ?
            <span className="imgContainer"><img src={ this.state.logo[0].dia } /></span> :
            <span className="imgContainer"><img src={ this.state.logo[0].noite }  /></span>
          }
          <div className="center">
            <a target="_blank" href={ this.state.socialMidia.instagram }><FontAwesomeIcon icon={['fab', 'instagram']} /> </a>
            <a target="_blank" href={ this.state.socialMidia.facebook }><FontAwesomeIcon icon={['fab', 'facebook']} /> </a>
            <a target="_blank" href={ this.state.socialMidia.twitter }><FontAwesomeIcon icon={['fab', 'twitter']} /> </a>
          </div>
          <div className="center">
            { this.state.texto.filter((i)=> i.idioma.trim() === this.props.idioma.trim()).map( (i,key)=> this.getTexto(i,key) )}
          </div>
        </footer>
    );

    }else{
      return (
        <footer className={this.props.className}>
        </footer>
      )
    }
  }
};
