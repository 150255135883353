import React, {Component} from 'react';
import {BrowserRouter , Route} from 'react-router-dom';

import Main from './components/Main';
import HeaderX from './components/Header/HeaderX';
import Footer from './components/Footer/Footer';
//import Blog from "./components/Blog/Blog"; 
import Contato from "./components/Contato/Contato"; 
import QuemSomos from "./components/QuemSomos/QuemSomos";
import AreaDoCliente from './components/AreaDoCliente/AreaDoCliente';
import Clientes from "./components/Clientes/Clientes";
import {Helmet} from "react-helmet";
import {library} from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {
    faBars,
    faTimes,
    faAt,
    faUserTie,
    faPhone,
    faLaptop,
    faAtom,
    faVrCardboard,
    faCloudDownloadAlt,
    faSun,
    faMoon,
    faArrowLeft,
    faImage,
    faImages

} from '@fortawesome/free-solid-svg-icons';
import Inovacao from './components/Inovacao/Inovacao';

library.add(faBars, faTimes, faAt, faUserTie, faFacebook, 
    faInstagram, faTwitter, faPhone, faLaptop, faAtom, 
    faVrCardboard, faCloudDownloadAlt, faSun, faMoon, faArrowLeft,
    faImage,  faImages
    );

type State = {
    idioma: string 
    nightMode: boolean,
}

type Props = {}
class App extends Component < Props, State > {
    state = {
        idioma: "",
        nightMode: this.isNight(),
        menu: []
    };

    getStorage = ()=>{
        if(localStorage.getItem('idioma')){
            return String(localStorage.getItem('idioma'));
        }
        localStorage.setItem('idioma', "pt-br");
        return String(localStorage.getItem('idioma'));
    }
    async componentWillMount() {
        this.setState({idioma: this.getStorage()})
    }
    setIdioma = (i : string) => {
        if (i === "pt-br") {
            this.setState({ idioma: "pt-br" })
        } else if (i === "en-us") {
            this.setState({ idioma: "en-us "})
        } else if (i === "zh-cn") {
            this.setState({ idioma: "zh-cn" })
        }else{
            this.setState({ idioma: "pt-br" })
        }
    }
    isNight() {
        if(sessionStorage.getItem("nightMode")){
            return sessionStorage.getItem("nightMode") === "true";
        }
        let data = new Date();
        if (true || data.getHours() <= 7 || data.getHours() > 19) 
            return true;
        return false;
    };

    nightMode() {
        return this.state.nightMode ? "nightMode" : "dayMode";
    }
    setNightMode=(mode:boolean)=>{
        //sessionStorage.setItem("nightMode", mode);
        this.setState({nightMode: mode});
    }

    render() {
        return (
            <BrowserRouter>
                <div>
                    <Helmet>
                        <meta http-equiv="Content-Language" content={this.state.idioma}/>
                        <title>Lemon CG</title>
                        <meta name='description' content=''/>
                    </Helmet>

                    <HeaderX
                        className={ this.nightMode() }
                        setNightMode={ this.setNightMode }
                        setIdioma={ this.setIdioma }
                        nightMode={ this.state.nightMode }
                    />

                    <Route exact path="/" render={() => (
                            <Main 
                                search={new URLSearchParams(location.search).get("category")} 
                                idioma={this.state.idioma} 
                                className={this.nightMode()}> 
                            </Main>
                        )
                    }/>
                   
                   <Route path="/inovacao" render={()=>
                        <Inovacao className={this.nightMode()}></Inovacao>
                    }/> 

                    <Route path="/area-do-cliente" render={()=>
                        <AreaDoCliente className={this.nightMode()}></AreaDoCliente>
                    }/> 
                    <Route path="/clientes" render={()=>
                        <Clientes className={this.nightMode()} nightMode={ this.state.nightMode }></Clientes>
                    }/> 
                    <Route path="/contato" render={()=>(
                        <Contato idioma={ this.state.idioma }  className={this.nightMode()} nightMode={ this.state.nightMode } ></Contato>)
                    }/>

                    <Route path="/sobre"  render={()=>(
                        <QuemSomos idioma={ this.state.idioma }  className={this.nightMode()} nightMode={ this.state.nightMode }></QuemSomos>
                    )}/>

                    <Footer idioma={this.state.idioma} nightMode={this.state.nightMode} className={this.nightMode()}/>
                </div>
            </BrowserRouter>
        );
    }
}
export default App;