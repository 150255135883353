import React, { Component } from 'react';
import Layout from '../Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Contato.scss"
import { getContato, sendMail } from '../../toolbox/wpApi';

type State = {
  mailValue: string,
  nameValue: string,
  textValue: string,
  message: string,
  error: string | boolean,
  textos: Array<{
    idioma: string,
    image: Array<{url:string}>,
    links:{
      email: string
      fone1: string
      fone2: string
    }
    textos:{
      nameEmpty: string
      mailEmpty: string 
      textEmpty: string
      sucessMessagem: string
      hangoutLabel: string
      nameLabel: string
      mailLabel: string
      enviarLabel: string
    }
  }>
}

type Props = {
  className: string
  idioma: string
  nightMode: boolean
}
export default class Contato extends Component<Props, State>  {
    state = {
        mailValue: '',
        nameValue: '',
        textValue: '',
        message: '',
        error: '',
        textos: [{
          idioma: "",
          image: [{url:''}],
          links: {
            email: "",
            fone1: "",
            fone2: "",
          },
          textos:{
            nameEmpty: "",
            mailEmpty: "",
            textEmpty: "",
            sucessMessagem: "",
            hangoutLabel: "",
            nameLabel: "",
            mailLabel: "",
            enviarLabel: ""
          }
        }]
    }
  getText=()=>{
    let labels = {
      nameEmpty: "",
      mailEmpty: "",
      textEmpty: "",
      sucessMessagem: "",
      hangoutLabel: "",
      nameLabel: "",
      mailLabel: "",
      enviarLabel: ""
    }
    this.state.textos.filter((i)=> i.idioma.trim() === this.props.idioma.trim()).map( i=>{
      labels = i.textos;
    })
    return labels
  }

  getLinks=()=>{
    let labels = {
      email: "",
      fone1: "",
      fone2: ""
    }
    this.state.textos.filter((i)=> i.idioma.trim() === this.props.idioma.trim()).map( i=>{
      labels = i.links;
    })
    return labels
  }


  
  async componentWillMount(){
		this.setState({ textos: await getContato() })
  }
  
  render() {
    const inputV = function(inpV){ return {
        "fontSize": (inpV) ? "12px" : "0px",
        "top": (inpV) ? "-17px" : "17px", 
    }}

  
    return (
      <Layout className={this.props.className}>
      <div id="contato"className="flex">
          <div className="fundoPreto" style={{display:(this.state.error)?"block":"none"}} onClick={ ()=>this.setState( {error:false} )}></div>
          <div className="userMessage" style={{
            display:(this.state.error)?"flex":"none",
            opacity:(this.state.error)?1:0,
            visibility:(this.state.error)?"visible":"hidden"
            }}>
            <p>{this.state.message}</p>
            <a className="x" onClick={ ()=>this.setState( {error:false} )}><FontAwesomeIcon icon="times" /></a>
          </div>
        <form onSubmit={ event => {
          event.preventDefault();
          if(!this.state.nameValue){
            this.setState({message:this.getText().nameEmpty})
            this.setState({error:"true"})
          } else if(!this.state.mailValue){
            this.setState({message:this.getText().mailEmpty})
            this.setState({error:"true"})
          } else if(!this.state.textValue){
            this.setState({message:this.getText().textEmpty})
            this.setState({error:"true"})
          }else{
            sendMail(
              this.state.nameValue,
              this.state.mailValue,
              this.state.textValue
            )
            this.setState({
                  message:this.getText().sucessMessagem,
                  error:"true",
                  mailValue: '',
                  nameValue: '',
                  textValue: ''
               })
          }
        }}>
        <p>{this.getText().hangoutLabel}</p>

            <div className="input-group">
              <label className="label right" style={inputV(this.state.nameValue)} htmlFor="name">{this.getText().nameLabel}</label>
              <input id="name" name="name" className="right" placeholder={this.getText().nameLabel} type="text" value={this.state.nameValue} onChange={ event => this.setState({ nameValue:event.target.value }) }/>
            </div>
             <div className="input-group">
                <label className="label" style={inputV(this.state.mailValue)} htmlFor="email">{this.getText().mailLabel}</label>
                <input id="email" name="email" placeholder={this.getText().mailLabel} type="email" value={this.state.mailValue} onChange={ event => this.setState({ mailValue:event.target.value }) }/>
            </div>
          <div className="input-group textArea">
              <textarea id="message" name="message" rows={10} cols={30} placeholder="" value={this.state.textValue} onChange={ event => this.setState({ textValue:event.target.value }) }></textarea>
          </div>
          <div className="input-group">
              <input id="submit" type="submit" value={this.getText().enviarLabel}/>
          </div>

          <div className="outrosContatos">
            <p><strong>TELEFONE</strong><br/><a href={"tel:"+this.getLinks().fone1.trim()}>{this.getLinks().fone1}</a> / 
            <a href={"tel:"+this.getLinks().fone2.trim()}>{this.getLinks().fone2}</a></p>
              <br/>
            <p><strong>E-MAIL</strong><br/><a href={"mailto:"+this.getLinks().email}>{this.getLinks().email}</a> </p>
          </div>
        </form>
      </div>
      </Layout>
    );
  }
};
