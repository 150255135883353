import React, { Component } from 'react';

type State = {

}

type Props = {
  className: string
}

export default class Layout extends Component<Props, State> {

  render() {
    return (
        <main className={this.props.className}> 
          { this.props.children }
        </main>
    );
  }
};
