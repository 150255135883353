import React, { Component } from 'react';

type State = {
    mont: string,
    monts: boolean,
}
type Props = {
	src:string,
	key:number,
	link: string,
	clicked: (string)=>void
}

export default class TileD extends Component<Props,State>  {
	state = {
		mont: "tile",
		monts: false
	};

	render() {
		const {src, link, clicked} = this.props;
		return (
			<div className={this.state.mont} onClick={()=>clicked(link)}>
				<img src={src} alt="an image" />
			</div>
		);
	}
	
}
