import React from 'react';

const VideoIframe = (src) => {
const tsrc = src.src.replace("watch?v=","embed/");
if(!tsrc){
  return (<span></span>)
}

const width = window.screen.width;
const height = window.screen.height*0.7;

return (
  <iframe
    title="lemongCG360"
    width={width+""}
    height={height+""}
    src={tsrc}
    frameBorder="0" 
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
    allowFullScreen
    accelerometer="true"
    gyroscope="true"
    style={{
      maxWidth: '97%',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
  />
)


};

export default VideoIframe;