import React, { Component,Fragment } from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import VideoIframe from "./VideoIframe.js";

type State = { 
    imgSet: Array<string>
    photoIndex: number
    isOpen: boolean
}
type Props = { 
    id: number
    imgSet: Array<{
        legenda: string
        video: {
            url: string,
            tumb: string
        }
        medias: Array<{
            url:string
            sizes:{
                medium: {file: string}
                thumbnail: {file: string}
            }
        }>
    }> 
}

export default class LightBox extends Component<Props, State> {
    state={
        imgSet:[],
        photoIndex: 0,
        isOpen: false
    }

    
    async UNSAFE_componentWillMount(){
        let imgs:Array<any> = [];
        this.setState({ photoIndex: this.props.id })
        this.props.imgSet.map( (img) =>{
            img.medias.forEach(media=>{
                if(img.video.url){
                    imgs.push(<VideoIframe src={img.video.url}/>)
                }else{
                    imgs.push( media.url)
                }
            })
        })
        this.setState({imgSet:imgs})
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let imgs:Array<any> = [];
        if(nextProps.imgSet.map( (img) =>{
            img.medias.forEach(media=>{
                if(img.video.url){
                    imgs.push(<VideoIframe src={img.video.url}/>)
                }else{
                    imgs.push( media.url)
                }
            })
        })!==prevState.imgSet){
            return {imgSet:imgs};
         }
         else return null;
    }
   
    ClickOutsideToClose(){
        alert("fechou?")
    }
    next(){
        const { imgSet, photoIndex } = this.state;
        return (photoIndex < imgSet.length) ?  imgSet[photoIndex+1]: undefined
    }
    prev(){
        const { photoIndex, imgSet } = this.state;
        return (photoIndex > 0) ? imgSet[photoIndex-1] : undefined
    }


    render() {
        const { imgSet, photoIndex, isOpen } = this.state;

        return (
            <Fragment>
                <a className="item-portifolio" onClick={() => this.setState({ isOpen: true, photoIndex: this.props.id })}>
                    { this.props.children }
                </a>
                {isOpen && ( 
                <Fragment>
                <a className="close-btn" onClick={() => this.setState({ isOpen: false, photoIndex: this.props.id })}>X</a>
                <Lightbox
                    mainSrc={imgSet[photoIndex]}
                    nextSrc={this.next()}
                    prevSrc={this.prev()}
                    clickOutsideToClose={true}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() => this.setState({
                        photoIndex: (photoIndex + imgSet.length - 1) % imgSet.length,
                    })}
                    imagePadding={30}
                    onMoveNextRequest={() =>  this.setState({
                        photoIndex: (photoIndex + 1) % imgSet.length,
                    })}
                />
                </Fragment>)}
            </Fragment>
        );
    }
};
